import React from 'react';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';

import './App.css';
import {Provider} from 'react-redux';
import store from "./redux/store";
import IncludeContainer from "./components/Include/IncludeContainer";
import ThemeContainer from "./components/Theme/ThemeContainer";

const App = (state: any) => {


    return (
        <Router>
            <Provider store={store}>
                <div>
                    <IncludeContainer/> {/* Exit & Language */}
                    <ThemeContainer/>   {/* Theme and Main */}
                </div>
            </Provider>
        </Router>
    );
};

export default App;
